<template>
  <div class="page-news-detail">
    <Loading v-if="loading" :count="6"></Loading>
    <el-descriptions v-else :column="1">
      <el-descriptions-item label="标题(中文)">
        {{ detail.title }}
      </el-descriptions-item>
      <el-descriptions-item label="标题(英文)">
        {{ detail.titleEn }}
      </el-descriptions-item>
      <el-descriptions-item label="文章分类">
        <span v-if="detail.articleType === 1">新闻</span>
        <span v-if="detail.articleType === 2">通知</span>
        <span v-if="detail.articleType === 3">往届作品</span>
      </el-descriptions-item>
      <el-descriptions-item label="图片(中文)">
        <el-image
          style="width: 100px; height: 100px"
          :src="detail.showImg"
          :preview-src-list="[detail.showImg]"
        ></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="图片(英文)">
        <el-image
          style="width: 100px; height: 100px"
          :src="detail.showImgEn"
          :preview-src-list="[detail.showImgEn]"
        ></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="发布日期	">
        {{ detail.articleDate }}
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import Loading from '@/components/loading'

export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      detail: null
    }
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsArticle.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.detail = res
    }
  }
}
</script>

<style lang="scss" scoped>
.page-news-detail {
  ::v-deep {
    .el-descriptions-item__container {
      min-height: 40px;
      line-height: 40px;
    }
    .el-descriptions-item__label {
      width: 120px;
      text-align: right;
    }
  }
}
</style>
